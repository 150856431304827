/*stylelint-disable*/
.full-width {
  &.hero {
    .container {
      padding-left:  0;
      padding-right: 0;
      .mit-logo {
        position: absolute;
        right: 0;
        width: 100px;
        z-index: 1000;
        @include media-breakpoint-up(md) {
          width: 150px;
        }
      }
    }
  }
}



.hero {
  .swiper-slide { 
    position: relative; 
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    img,
    iframe,
    object,
    embed { 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .slide-text {
      position: absolute;
      left: $grid-gutter-width/2;
      top:50%;
      transform: translateY(-50%);
      z-index: 9999;
      .occhiello,
      .titolo {
        text-transform: uppercase;
      }
      .occhiello {
        line-height: 0;
      }
      .titolo {
        color: $primary;
      }
      .btn {
        width: fit-content;
        position: relative;
      }
    }
  }
  .swiper-pagination {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.slider-inline {
  --swiper-theme-color: #fff;
  .swiper-slide {
    height: auto;
    .row {
      height: 100%;
      .titolo {
        border-bottom: 1px solid $black;
      }
      .text {
        color: $black;
        @include media-breakpoint-up(lg) {
          color: $body-color;
          background: $light;
        }
      }
      .logo-confindustria {
        position: absolute;
        bottom: 0;
      }
    }
  }
  .freccia {
    @include media-breakpoint-up(lg) {
      &:before,
      &:after {
        content:"";
        position: absolute;
        width: 20px;
        height: 50%;
        transform: translate(0, 0); //non so perché ma così su windows non ha bug
        z-index: 100;
        left: 0;
      }
      &:before {
        background-image: linear-gradient(to right top, #fed500 0%, #fed500 50%, transparent 51%);
        top: 0;
      }
      &:after {
        content:"";
        background-image: linear-gradient(to right bottom, #fed500 0%, #fed500 50%, transparent 51%);
        top: 50%;
      }
    }
  }
  .swiper-pagination {
    position: relative;
    line-height: 0;
    bottom: 0;
    padding-top: 1rem;
    @include media-breakpoint-up(md) {
      position: absolute;
      bottom: 1rem;
      left: 66.6666666667%;
      transform: translateX(-50%);
    }
  }
}
