/* stylelint-disable */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

:root {
  --swiper-theme-color: #{$primary};
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  @include font-size($font-size-sm);
  font-family: "Roboto", $font-family-sans-serif;
  @include media-breakpoint-up(md) {
    @include font-size($font-size-base);
  }
  overflow-x:hidden;
}

img {
  width: 100%;
  height: auto;
  &.img-cover {
    height: 100%;
    object-fit: cover;
  }
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
  font-family: "Roboto", $font-family-sans-serif;
  text-transform: uppercase;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btn-black, .btn-primary {
  text-transform: uppercase;
  font-weight: 700;
}

.full-width {
  margin-left  : calc( -50vw + 50%);
  margin-right : calc( -50vw + 50%);
  width: 100vw;

}

.years-of-history,
.mappa-distributori {
  .title {
    text-transform: uppercase;
  }
}

/* navs */
.nav-link {
  display: inline-block;
}


a.color-black{
  color: $black;
  &:hover{
    color: $primary;
  }
}

.navbar {

  .dropdown:hover > .dropdown-menu {
    display: block; }

  .dropdown-menu {
    border:0;
    margin-top: -2px;
    background-color: $black;
    a {
      color: $white;
    }
  }
}

/* blocks */
.fmgl-cards {
  .fmgl-card {
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    @include media-breakpoint-down(md) {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
      @include media-breakpoint-up(md) {
        border-bottom: none;
      }
    }
    img {
      max-height: 45vh;
      width: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 3rem;
      @include media-breakpoint-up(md) {
        width: 100%;
        max-height: none;
        left: auto;
        transform: none;
      }
      @include media-breakpoint-up(lg) {
      }
    }
  }
}



.schema-tecnologia {
  .intro {
    p {
      margin-bottom: 0;
    }
  }
  .schema {
    .testo {
      p:not(:last-child) {
        border-bottom: 1rem solid $light;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
      }
      strong {
        color: $black;
      }
      img {
        max-width: 500px;
        margin: auto;
        display: block;
      }
      @include media-breakpoint-down(md) {
        order:2;
      }
    }
  }
}


/* downloads */
.page-template-downloads {
  .prodotto-download {
    display: grid;
    grid-template-columns: 33% 67%;
    align-items: center;
    h3 {
      margin-bottom: 0;
      font-size: 1.2rem;
      line-height: 1;
    }
    .download-files {
      display: grid;
      grid-template-columns: repeat(3, 33%);
      grid-auto-rows: 2rem;
      text-transform: capitalize;
      align-items: center;
      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $body-color;

        &:hover, .icon {
          color: $primary;
        }

      }
    }
  }
  .navigation.pagination {
    margin-top: 2rem;
    .nav-links {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

#downloads-sidebar {
  .download {
    border-bottom: 1rem solid #e9ecef;
    strong {
      color: $black;
    }
    a {
      .thumbnail {
        display:none;
        @include media-breakpoint-up(md) {
          display: flex;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}

/* archivio prodotti */
.archive {
  .prodotto {
    &:last-of-type {
      padding-bottom: 1.5rem;
      @include media-breakpoint-up(md) {
        padding-bottom: 2rem;
      }
    }
  }
}

.prodotto {
  .thumbnail {
    overflow: hidden;
    img{
      transition: transform .5s;
    }
    &:hover{
      img{
        transform: scale(1.2);
      }
    }
  }
}

.archivio-prodotti {
  .prodotto {
    a {
      display:block;
      &:hover {
        .thumbnail {
          opacity: .85;
        }
      }

      .category {
        min-height: 1.5rem;
      }
    }
  }
}

/* singola prodotto */
.single-prodotto {
  article.prodotto{
    a {
      color: $body-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .prodotto {
    h6 {
      margin-bottom: 0;
    }

    .scheda-prodotto {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .colore-corpo,
    .colore-diffusore,
    .colore-retro,
    .certificazioni {
        img {
          height: 3rem;
          width: auto;
        }
    }
  }
  a.swiper-slide {
    div {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding-bottom: 140%;
    }
    &.video-slide {
      .play-hover {
        position: absolute;
        top:0;
        width: 100%;
        height: 100%;
        background-image: url('/images/play.svg');
        background-size: 50%;
        &:hover {
          filter: brightness(1.05);
        }
      }
    }
  }
  .gallery-thumbs {
    padding: 10px 0 30px;
    .swiper-slide {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 25%;
      opacity: 0.4;
      padding-bottom: 35%;
      cursor: pointer;
      &.swiper-slide-thumb-active,
      &:hover {
        opacity: 1;
      }
      &.video-slide{
        background-size: contain !important;
        position: relative;

      }
      &.video-slide:after {
        content: ' ';
        position: absolute;
        top:0; bottom:0; left:0; right:0;
        background-image: url('/images/play.svg');
        background-size: contain !important;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: .5;
      }
    }
    .swiper-button-disabled {
      display: none;
    }
  }
//  .video-container {
//    position: relative;
//    padding-bottom: 56.25%;
//    overflow: hidden;
//    max-width: 100%;
//    height: auto;
//    iframe {
//      position: absolute;
//      top: 0;
//      left: 0;
//      width: 100%;
//      height: 100%;
//    }
//  }
  .prodotti-correlati {
    .prodotto-correlato {
      a {
        display:block;

        &:hover {

          .thumbnail {
            opacity: .85;
          }
        }

      }
    }
  }
}


/* sidebars */
#downloads-sidebar-button,
#product-filters-sidebar-button,
#news-sidebar-button {
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: $black;
  }
}

.sidebar-container {
  @include media-breakpoint-up(md) {
    border-bottom: none;
  }
}

.dropdown-toggle[aria-expanded="true"] {
  &:after {
    transform: rotate(180deg);
  }
}

.wp-block-columns.contacts{
  margin-top: 3rem;
  label, input, textarea{
    width: 100%;
  }
}

.main {
  //Swiper global tweaks
  span.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: $white;
    opacity: .75;
    margin:0 .5rem !important;
  }

  span.swiper-pagination-bullet-active {
    background-color: black;
    @include media-breakpoint-up(md) {
      background-color: $primary;
    }

    opacity: 1;
  }
}


/*
 * Cookie Banner
 */
 #cookie-banner {
  background-color: $body-bg;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: -100%;
  left: 0;
  right:0;
  z-index: 1000;

  transition: bottom 1.5s ease-in-out;

  &.active {
    bottom:0;
  }
}
.grecaptcha-badge{
  display: none;
}
.contattaci .grecaptcha-badge{
  display: block;
}

#categories-list {
  .cat-item a, .cat-item-all a{
       color: $body-color;
    }
  .cat-item.current-cat a,
  .cat-item-all a:hover,
  .cat-item a:hover {
    color: $primary;
  }

}

