/*stylelint-disable*/
/** Search form */
.search-form {
  @extend .form-inline;
}

.search-form label {
  @extend .form-group;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

footer{
  .form-control{
    &:focus{
      background-color: $dark;
    }
    background-color: $dark;
    border-color: $gray-600;
  }
}

#search-group{
  input{
    border-right:0;
  }
  span{
    border-left:0;
    background:#fff;
  }
}

.custom-checkbox{
  .custom-control-label{

  }
  .custom-control-label::before{
    background-color: transparent;
    border-color:transparent;
    font-family: 'Fumagalli';
    content:'\0045';
    color: $gray-200;
    font-size:1rem;
    line-height:1;
  }
  .custom-control-input:checked{
    & ~ .custom-control-label::before{
      background-color: transparent;
      border-color:transparent;
    }

    & ~ .custom-control-label::after{
      font-family: 'Fumagalli';
      background-color: transparent;
      content: '\0044';
      color:$primary;
      font-size:1em;
      line-height:1;
    }
  }
}
