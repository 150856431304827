/*stylelint-disable*/
footer {
  font-size: .9rem;
  @include media-breakpoint-down(md) {
    line-height: 1.25;
  }
  .title {
    text-transform: uppercase;
  }
  .nav-link {
    color: $body-color;
  }
}
.logo-footer-container {
  position: relative;
  height: 3.75rem;
  @include media-breakpoint-up(lg) {
    height: 6rem;
  }
  .logo-footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -2.5rem;
  }
}

#mc_embed_signup {
  .mc-field-group {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 0;
    &.gdprRequired {
      input {
        margin-right: 1rem;
      }
    }
    label {
      margin-bottom: 0;
    }
  }
}

#mergeRow-gdpr {
  margin-top: .5rem;
  font-size: 80%;
  label.checkbox {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
}

#mc-embedded-subscribe {
  border-width: 1px;
}