/*stylelint-disable*/
// Custom variables
$btn-freccia-width: .5rem;


// Color system
$white:    #fff !default;
$gray-200: #e9ecef !default;
$gray-300: #ccc;
$gray-600: #616161;
$gray-800: #242424;
$black:    #000 !default;

$yellow:  #fed500;
$primary:       $yellow;
$light:         $gray-200;


$theme-colors: (
    "black":      $black
  );

$enable-responsive-font-sizes:                true;


$body-color:                $gray-600;
$link-hover-decoration:                   none;
$button-hover-color: red;
// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


$border-radius:               2px;
$border-radius-lg:            2px;
$border-radius-sm:            2px;

$btn-font-weight:             bold;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$h2-font-size:                $font-size-base * 4;


$headings-color:              $black;

$input-btn-border-width:      0;

$input-btn-font-size-sm:      $font-size-sm !default;
$input-font-size:                       $input-btn-font-size-sm;

$input-bg:                              $white;


$input-color:                           $body-color;

$input-border-width:                    1px;


$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $primary;
$navbar-dark-active-color:          $primary;
$navbar-dark-disabled-color:        rgba($white, .5);

$navbar-dark-toggler-border-color:  rgba($white, 0);

