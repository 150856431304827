/*stylelint-disable*/
.banner .nav li {
  @extend .nav-item;
}

.banner .nav a {
  @extend .nav-link;
}

#site-logo {
  width: 100px;
  @include media-breakpoint-up(lg) {
    width: 150px;
  }
}

.navbar-collapse {
  @include media-breakpoint-up(lg) {
    max-width: 75%;
    margin-left: auto; 
  }
}

.wpml-ls-current-language {
  a.nav-link {
    color: $primary !important;
  }
}
li.current_page_parent{
  
  a.nav-link{
   /* color: $primary !important; */
    
  }
}

.top_menu {
  margin-left: -0.5rem;

  .nav-link {
    padding: 1rem .5rem;
  }
}
