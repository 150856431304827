/*stylelint-disable*/
@import "common/variables";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";
//Swiper importo i singoli componenti per limitare dimensione css.
@import "~swiper/swiper";
@import "~swiper/components/pagination/pagination";
@import "~swiper/components/navigation/navigation";

/** Import theme styles */
@import "common/icons-fumagalli";
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/slider";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "~swiper/swiper-bundle.css";
@import "~ekko-lightbox/dist/ekko-lightbox.css";
