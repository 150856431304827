/*stylelint-disable*/
.btn {
  &.btn-primary {
    --arrow-color: #{$primary};
    &:hover {
      background-color: $primary;
    }
  }
  &.btn-black {
    --arrow-color: #{$black};
    &:hover {
      background-color: $black;
    }
  }
  &.br {
    position: absolute;
    bottom: -20px;
    right: calc(#{$grid-gutter-width}/2 + #{$btn-freccia-width});
    @include media-breakpoint-up(md) {
      bottom: 0;
    }
  }
  &:before,
  &:after {
//    content:"";
    position: absolute;
    width: $btn-freccia-width;
    height: 50%;
    right: -$btn-freccia-width;
  }
  &:before {
    background-image: linear-gradient(to right top, var(--arrow-color) 0%, var(--arrow-color) 49%, transparent 51%);
    top: 0;
  }
  &:after {
    background-image: linear-gradient(to right bottom, var(--arrow-color) 0%, var(--arrow-color) 49%, transparent 51%);
    top: 50%;
  }
}
