/* stylelint-disable */
/* Generated by Glyphter (http://www.glyphter.com) on  Sun Feb 28 2021*/
@font-face {
    font-family: 'Fumagalli';
    src: url('/fonts/Fumagalli.eot');
    src: url('/fonts/Fumagalli.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Fumagalli.woff') format('woff'),
         url('/fonts/Fumagalli.ttf') format('truetype'),
         url('/fonts/Fumagalli.svg#Fumagalli') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Fumagalli';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-file-pdf:before{content:'\0041';}
.icon-file-archive:before{content:'\0042';}
.icon-file-zip:before{content:'\0042';}
.icon-arrow-to-bottom:before{content:'\0043';}
.icon-check-square:before{content:'\0044';}
.icon-square:before{content:'\0045';}
.icon-search:before{content:'\0046';}
